"use client";
import React, { Fragment, useContext, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import {
  Dialog,
  Transition,
  Menu,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  UserCircleIcon,
  ChevronRightIcon,
  HomeIcon,
  IdentificationIcon,
  ChatBubbleLeftRightIcon,
  DocumentCurrencyDollarIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { navbarTabs } from "@/app/models/constants/navbarTabs";
import Tabs from "./Tabs";
import { UserContext } from "../utils/user";
import withNoSSR from "../withNoSSR";
import Image from "next/image";
import { RoleOptions, User } from "../types";
import { makeAPIRequest } from "../api/apiWrapper";
import { classNames } from "../utils/tailwindHelper";
import NotificationMenu from "./NotificationMenu";
import Link from "next/link";
import ReactGA from "react-ga4";
import BreadCrumb from "./Breadcrumb";

const staticPrefixesNotRequiringAuth = [
  "/privacy-policy",
  "/terms-and-conditions",
];

interface UserNavigationOption {
  name: string;
  href: string;
}

const userNavigationOptions: UserNavigationOption[] = [
  { name: "Your profile", href: "/profile" },
  { name: "Sign out", href: "/" },
];

const Navbar: React.FC<any> = ({ logOut, children }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();
  const routerPath = usePathname() as string;

  const user = useContext(UserContext).user as User;
  if (!user) {
    // static public pages
    if (
      staticPrefixesNotRequiringAuth.some((prefix) =>
        routerPath.startsWith(prefix)
      )
    ) {
      return <>{children}</>;
    }
    router.push("/login");
  }

  interface NavigationItem {
    name: string;
    href?: string | ((user: User) => string);
    displayCondition?: (user: User) => boolean;
    icon?: React.ReactNode;
    children?: NavigationItem[];
  }

  const navigation: NavigationItem[] = [
    {
      name: "Home",
      href: "/",
      icon: <HomeIcon className="h-5 w-5 shrink-0 text-gray-400" />,
    },
    {
      name: "Applications",
      href: (user: User) => `/company/${user.companyId}/application`,
      icon: <DocumentTextIcon className="h-5 w-5 shrink-0 text-gray-400" />,
      displayCondition: (user: User) =>
        user?.companyId != null && user?.role == RoleOptions.Company,
    },
    {
      name: "My Company",
      displayCondition: (user: User) => user?.companyId != null,
      children: [
        {
          name: "Information",
          href: `/company/${user?.companyId}/information`,
          displayCondition: (user: User) => user?.companyId != null,
        },
        {
          name: "Team Management",
          href: `/company/${user?.companyId}/team`,
          displayCondition: (user: User) =>
            user?.companyId != null && user?.role == RoleOptions.Company,
        },
        {
          name: "Products",
          href: `/company/${user?.companyId}/products`,
          displayCondition: (user: User) => user?.role == RoleOptions.Company,
        },
        // {
        //   name: "Deals",
        //   href: `/company/${user?.companyId}/deals`,
        //   displayCondition: (user: User) =>
        //     `${process.env.NODE_ENV}` === "development",
        // },
        // {
        //   name: "Milestones",
        //   href: `/company/${user?.companyId}/milestones`,
        //   displayCondition: (user: User) =>
        //     `${process.env.NODE_ENV}` === "development",
        // },
        {
          name: "Diligence",
          href: `/company/${user?.companyId}/diligence`,
          displayCondition: (user: User) =>
            user?.company?.cohortMembership?.length > 0 &&
            (user?.company?.cohortMembership.includes("apac2024") ||
              user?.company?.cohortMembership.includes("2024") ||
              user?.company?.cohortMembership.includes("biotools2024")),
        },
      ],
    },
    {
      name: "Open Round",
      href: `/company/${user?.companyId}/open-round`,
      displayCondition: (user: User) =>
        user?.company?.cohortMembership?.length > 0,
      icon: (
        <DocumentCurrencyDollarIcon className="h-5 w-5 shrink-0 text-gray-400" />
      ),
    },
    {
      name: "Open Rounds",
      href: `/open-rounds`,
      displayCondition: (user: User) =>
        user?.openRoundsAccess || user?.role == RoleOptions.Admin,
      icon: (
        <DocumentCurrencyDollarIcon className="h-5 w-5 shrink-0 text-gray-400" />
      ),
    },
    {
      name: "Portfolio Directory",
      href: "/portfolio-directory",
      displayCondition: (user: User) =>
        user?.company?.cohortMembership?.length > 0 ||
        (user?.role &&
          [RoleOptions.Admin, RoleOptions.Partner].includes(user.role)),
      icon: <IdentificationIcon className="h-5 w-5 shrink-0 text-gray-400" />,
    },
    {
      name: "Ecosystem Directory",
      href: "/ecosystem-directory",
      displayCondition: (user: User) =>
        `${process.env.NODE_ENV}` === "development" &&
        user?.role == RoleOptions.Admin,
      icon: <IdentificationIcon className="h-5 w-5 shrink-0 text-gray-400" />,
    },
    // {
    //   name: "My Nominations",
    //   href: "/nominations",
    //   displayCondition: (user: User) =>
    //     `${process.env.NODE_ENV}` === "development" ||
    //     (user?.role &&
    //       [RoleOptions.Admin, RoleOptions.Partner, RoleOptions.Judge].includes(
    //         user.role
    //       )), // maybe make this if the user has their userId on any nominations?
    //   icon: <TrophyIcon className="h-5 w-5 shrink-0 text-gray-400" />,
    // },
    {
      name: "Forum Discussions",
      href: "/forum/general",
      displayCondition: (user: User) =>
        user?.company?.cohortMembership?.length > 0 ||
        (user?.role &&
          [RoleOptions.Admin, RoleOptions.Partner].includes(user.role)),
      icon: (
        <ChatBubbleLeftRightIcon className="h-5 w-5 shrink-0 text-gray-400" />
      ),
    },
  ];

  async function signOut(): Promise<void> {
    await makeAPIRequest("auth/logout", "GET");
    localStorage.removeItem("user");
    ReactGA.set({ userId: undefined });
    router.push("/login");
  }

  if (user) {
    return (
      <div>
        {/* Mobile Version */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
                    <div className="flex shrink-0 items-center">
                      <img
                        className="w-auto mx-auto"
                        src="https://media.innovator.org/public/static/AllProgramsHeader_1200x300.png"
                        alt="MedTech Innovator"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col px-6">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation
                              .filter(
                                (item) =>
                                  !item?.displayCondition ||
                                  item.displayCondition(user)
                              )
                              .map((item: any) => (
                                <li key={item.name}>
                                  {item.href ? (
                                    <>
                                      {item.icon ? (
                                        <Link
                                          href={
                                            typeof item.href === "function"
                                              ? item.href(user)
                                              : item.href
                                          }
                                          onClick={() => setSidebarOpen(false)}
                                          className={
                                            "hover:bg-gray-50 group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700"
                                          }
                                        >
                                          {item.icon}
                                          {item.name}
                                        </Link>
                                      ) : (
                                        <Link
                                          href={
                                            typeof item.href === "function"
                                              ? item.href(user)
                                              : item.href
                                          }
                                          onClick={() => setSidebarOpen(false)}
                                          className={
                                            "block rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                          }
                                        >
                                          {item.name}
                                        </Link>
                                      )}
                                    </>
                                  ) : (
                                    <Disclosure as="div" defaultOpen>
                                      <DisclosureButton
                                        className={
                                          "hover:bg-gray-50 group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700"
                                        }
                                      >
                                        <ChevronRightIcon
                                          aria-hidden="true"
                                          className="h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                        />
                                        {item.name}
                                      </DisclosureButton>
                                      <DisclosurePanel
                                        as="ul"
                                        className="mt-1 px-2"
                                      >
                                        {(item.children as NavigationItem[])
                                          .filter(
                                            (subItem: any) =>
                                              !subItem?.displayCondition ||
                                              subItem.displayCondition(user)
                                          )
                                          .map((subItem: any) => (
                                            <li key={subItem.name}>
                                              <Link
                                                href={subItem.href || ""}
                                                onClick={() =>
                                                  setSidebarOpen(false)
                                                }
                                                className={
                                                  "hover:bg-gray-50 block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700"
                                                }
                                              >
                                                {subItem.name}
                                              </Link>
                                            </li>
                                          ))}
                                      </DisclosurePanel>
                                    </Disclosure>
                                  )}
                                </li>
                              ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="z-10">
          <div className="sticky top-0 z-10 print:hidden">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-ad_dark_blue px-4 shadow-sm sm:gap-x-6 sm:px-6 text-white">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-white"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

              <div className="flex-1 gap-x-4 self-stretch lg:gap-x-6 flex">
                <div className="hidden lg:flex">
                  <Tabs tabs={navbarTabs} />
                </div>
                <div className="flex items-center gap-x-4 ml-auto lg:gap-x-6">
                  <NotificationMenu />

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      {user.profileImageUrl ? (
                        <Image
                          width={0}
                          height={0}
                          unoptimized={true}
                          className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                          src={user.profileImageUrl}
                          alt=""
                        />
                      ) : (
                        <UserCircleIcon
                          className="h-8 w-8 text-gray-300"
                          aria-hidden="true"
                        />
                      )}
                      <span className="hidden lg:flex lg:items-center">
                        <span
                          className="ml-4 text-sm font-semibold leading-6 text-white"
                          aria-hidden="true"
                        >
                          {`${user.firstName || ""} ${user.lastName || ""}`}
                        </span>
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-[200] mt-2.5 w-32 origin-top-right bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigationOptions.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  if (item.href === "/") {
                                    signOut();
                                  } else {
                                    router.push(item.href); // Redirect to profile page otherwise
                                  }
                                }}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <BreadCrumb />
          </div>
          <main>
            <div className="mx-auto">{children}</div>
          </main>
        </div>
      </div>
    );
  }
};

export default withNoSSR(Navbar);
